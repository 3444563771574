main[data-template="emissaries"] {

    $colwidth: 600px;
    $gaps: 100px;
    padding-top: 125px;
    min-height: calc(100vh - 290px);

    h1 {
        top: auto;
    }

    #emissaries {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(500px,1fr));
        margin: 0 $gaps;

        article {
            display: grid;
            grid-template-columns: 240px 1fr;

            margin-bottom: 60px;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &>div {
                padding: 20px 25px;

                h2 {
                    margin: 0;
                    font-size: 24px;
                }
            }
        }
    }

    @media (max-width: 800px) {
        #emissaries {
            grid-template-columns: 1fr;
            margin: 0 50px;

            article {
                grid-template-columns: 1fr;
                margin-bottom: 0;

                img {
                    height: 350px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        #emissaries {
            margin: 0 25px;
        }
    }

}